<template>
  <q-dialog ref="dialog" @hide="onDialogHide">
    <q-card class="q-dialog-plugin">
      <p class="text-h6 text-grey-8 q-mt-sm text-center">PRESTASI</p>
      <q-card-section class="column q-col-gutter-sm">
        <q-input
          outlined
          readonly
          label="Nama Siswa"
          :value="prestasi.nama_siswa"
        ></q-input>
        <q-input
          outlined
          readonly
          label="Angkatan"
          :value="prestasi.tahun_masuk"
        ></q-input>
      </q-card-section>
      <q-separator></q-separator>
      <q-card-section class="column q-col-gutter-sm">
        <q-input
          outlined
          readonly
          autogrow
          label="Prestasi"
          :value="prestasi.nama"
        ></q-input>
        <q-input
          outlined
          readonly
          label="Bidang"
          :value="prestasi.bidang"
        ></q-input>
        <q-input
          outlined
          readonly
          label="Penyelenggara"
          :value="prestasi.penyelenggara"
        ></q-input>
        <q-input
          outlined
          readonly
          label="Tingkat"
          :value="prestasi.tingkat"
        ></q-input>
        <q-input
          outlined
          readonly
          label="Kategori"
          :value="prestasi.kategori"
        ></q-input>
      </q-card-section>
      <q-card-actions align="right">
        <q-btn
          flat
          color="primary"
          label="Close"
          @click="onCancelClick"
        ></q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  props: ["prestasi"],
  data() {
    return {};
  },
  methods: {
    show() {
      this.$refs.dialog.show();
    },

    hide() {
      this.$refs.dialog.hide();
    },

    onDialogHide() {
      this.$emit("hide");
    },

    onOKClick() {
      this.$emit("ok");
      this.hide();
    },

    onCancelClick() {
      this.hide();
    }
  }
};
</script>
