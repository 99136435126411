<template>
  <div class="row justify-center bg-grey-3">
    <q-card flat bordered style="width:300px">
      <q-tabs
        dense
        indicator-color="orange"
        align="center"
        inline-label
        active-color="white"
        class="bg-blue-12 text-blue-11 shadow-2"
        v-model="tab"
      >
        <q-tab label="Prestasi" name="prestasi"></q-tab>
        <q-tab label="Beasiswa" name="beasiswa"></q-tab>
      </q-tabs>
      <q-tab-panels v-model="tab">
        <q-tab-panel name="prestasi" class="q-pa-none">
          <q-card-section>
            <p class="q-mb-none text-center">Peraih Prestasi</p>
            <q-markup-table
              dense
              flat
              bordered
              separator="cell"
              class="stickyTable"
              style="height:115px"
              v-if="stat.pres[0]"
            >
              <thead>
                <tr>
                  <th>Jenjang</th>
                  <th v-for="(tahun, t) in stat.pres[0].data" :key="t">
                    {{ tahun.tahun }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(jenjang, x) in stat.pres" :key="x">
                  <td>{{ jenjang.jenjang }}</td>
                  <td v-for="(data, y) in jenjang.data" :key="y">
                    {{ data.banyak }}
                  </td>
                </tr>
              </tbody>
            </q-markup-table>
          </q-card-section>
          <q-separator></q-separator>
          <q-card-section class="column q-col-gutter-sm items-center">
            <q-select
              dense
              outlined
              class="full-width"
              label="jenjang"
              :options="$LIST_JENJANG"
              v-model="selJenjang"
              @input="getData"
            ></q-select>
            <q-btn
              flat
              class="full-width"
              label="FILTER"
              color="negative"
              @click="filter_pres = true"
            ></q-btn>
          </q-card-section>
          <q-separator></q-separator>
          <q-markup-table
            flat
            dense
            wrap-cells
            separator="horizontal"
            class="stickyTable bg-grey-4"
            style="height: calc(100vh - 250px)"
          >
            <thead>
              <tr>
                <th>Nama</th>
                <th>Angkatan</th>
                <th>Prestasi</th>
              </tr>
            </thead>
            <tbody class="bg-white">
              <tr
                v-for="(val, i) in showSiswa"
                :key="i"
                @click="showPrestasi(val)"
              >
                <td>{{ val.nama_siswa }}</td>
                <td>{{ val.tahun_masuk }}</td>
                <td>{{ val.nama }}</td>
              </tr>
            </tbody>
          </q-markup-table>
          <q-card-actions align="center">
            <q-pagination
              v-model="pagination.current"
              :disable="siswa.length == 0"
              :max="pagination.max"
              input
              input-class="text-orange-10"
            />
          </q-card-actions>
        </q-tab-panel>
        <q-tab-panel name="beasiswa" class="q-pa-none">
          <q-card-section>
            <p class="q-mb-none text-center">Penerima Beasiswa</p>
            <q-markup-table
              dense
              flat
              bordered
              separator="cell"
              class="stickyTable"
              style="height:115px"
              v-if="stat.bea[0]"
            >
              <thead>
                <tr>
                  <th>Jenjang</th>
                  <th v-for="(tahun, t) in stat.bea[0].data" :key="t">
                    {{ tahun.tahun }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(jenjang, x) in stat.bea" :key="x">
                  <td>{{ jenjang.jenjang }}</td>
                  <td v-for="(data, y) in jenjang.data" :key="y">
                    {{ data.banyak }}
                  </td>
                </tr>
              </tbody>
            </q-markup-table>
          </q-card-section>
          <q-separator></q-separator>
          <q-card-section class="column q-col-gutter-sm">
            <q-select
              dense
              outlined
              label="jenjang"
              :options="$LIST_JENJANG"
              v-model="selJenjang"
              @input="getData"
            ></q-select>
            <q-btn
              flat
              class="full-width"
              label="FILTER"
              color="negative"
              @click="filter_bea = true"
            ></q-btn>
          </q-card-section>
          <q-separator></q-separator>
          <q-markup-table
            flat
            dense
            wrap-cells
            separator="horizontal"
            class="stickyTable bg-grey-4"
            style="height: calc(100vh - 250px)"
          >
            <thead>
              <tr>
                <th>Nama</th>
                <th>Tahun</th>
                <th>Beasiswa</th>
              </tr>
            </thead>
            <tbody class="bg-white">
              <tr v-for="(el, j) in showPenerima" :key="j" @click="showBea(el)">
                <td>{{ el.nama_siswa }}</td>
                <td>{{ el.tahun }}</td>
                <td>{{ el.nama_beasiswa }}</td>
              </tr>
            </tbody>
          </q-markup-table>
          <q-card-actions align="center">
            <q-pagination
              v-model="pagination_bea.current"
              :disable="penerima.length == 0"
              :max="pagination_bea.max"
              input
              input-class="text-orange-10"
            />
          </q-card-actions>
        </q-tab-panel>
      </q-tab-panels>
    </q-card>
    <q-dialog v-model="filter_pres" position="bottom">
      <q-card>
        <q-card-section class="column q-col-gutter-sm">
          <p class="text-bold">FILTER</p>
          <span>
            <a class="q-mb-sm text-bold">Periode:</a>
            <q-select
              dense
              outlined
              :options="period"
              v-model="selPeriod"
            ></q-select>
          </span>
          <span>
            <a class="q-mb-sm text-bold">Bidang:</a>
            <q-select
              dense
              outlined
              :options="bidang"
              v-model="selBidang"
            ></q-select>
          </span>
          <span>
            <a class="q-mb-sm text-bold">Tingkat:</a>
            <q-select
              dense
              outlined
              :options="tingkat"
              v-model="selTingkat"
            ></q-select>
          </span>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn dense flat color="primary" label="Close" v-close-popup></q-btn>
          <q-btn
            dense
            unelevated
            color="primary"
            label="Apply"
            @click="getSiswa"
          ></q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>

    <q-dialog v-model="filter_bea" position="bottom">
      <q-card>
        <q-card-section class="column q-col-gutter-sm">
          <p class="text-bold">FILTER</p>
          <span>
            <a class="q-mb-sm text-bold">Nama Beasiswa:</a>
            <q-select dense outlined :options="bea" v-model="selBea"></q-select>
          </span>
          <span>
            <a class="q-mb-sm text-bold">Tahun:</a>
            <q-select
              dense
              outlined
              :options="tahun"
              v-model="selTahun"
            ></q-select>
          </span>
          <span>
            <a class="q-mb-sm text-bold">Sumber:</a>
            <q-select
              dense
              outlined
              :options="sumber"
              v-model="selSumber"
            ></q-select>
          </span>
          <span>
            <a class="q-mb-sm text-bold">Jenis:</a>
            <q-select
              dense
              outlined
              :options="jenis"
              v-model="selJenis"
            ></q-select>
          </span>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn dense flat color="primary" label="Close" v-close-popup></q-btn>
          <q-btn
            dense
            unelevated
            color="primary"
            label="Apply"
            @click="getPenerima"
          ></q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import DialogDetailPrestasi from "@/components/DialogDetailPrestasi";
import DialogDetailBea from "@/components/DialogDetailBea";
export default {
  data() {
    return {
      tab: "prestasi",
      filter_pres: false,
      filter_bea: false,
      selJenjang: this.$LIST_JENJANG[0],

      stat: {
        bea: [],
        pres: [],
      },

      period: [],
      selPeriod: null,

      bidang: [],
      selBidang: null,

      tingkat: [],
      selTingkat: null,

      siswa: [],
      showSiswa: [],

      bea: [],
      selBea: null,

      tahun: [],
      selTahun: null,

      penerima: [],
      showPenerima: [],

      jenis: ["SEMUA JENIS", "PRESTASI", "KURANG MAMPU", "TAHFIDZ", "LAINYA"],
      selJenis: null,

      sumber: [
        "SEMUA SUMBER",
        "KEMENAG",
        "KEMENDIKBUD",
        "KEMENTRIAN LAIN",
        "PEMERINTAH DAERAH",
        "BUMN",
        "BUMD",
        "INSTANSI SWASTA",
        "YAYASAN",
        "PERORANGAN",
        "GNOTA",
        "SUBSIDI INTERNAL A",
        "SUBSIDI INTERNAL B",
        "SUBSIDI INTERNAL C",
      ],
      selSumber: null,

      pagination: {
        current: 1,
        max: 99,
        limit: 10,
      },

      pagination_bea: {
        current: 1,
        max: 99,
        limit: 10,
      },
    };
  },
  async mounted() {
    this.$q.loading.show({ message: "Mohon Tunggu..." });
    await this.getStat();
    await this.getData();
    this.$q.loading.hide();
  },
  methods: {
    showPrestasi(val) {
      this.$q.dialog({
        component: DialogDetailPrestasi,
        parent: this,
        prestasi: val,
      });
    },
    showBea(el) {
      this.$q.dialog({
        component: DialogDetailBea,
        parent: this,
        bea: el,
      });
    },
    async getStat() {
      let resp = await this.$http.get(`/statistik/presbea/getstat`);
      this.stat = {
        pres: resp.data[0],
        bea: resp.data[1],
      };
    },
    async getData() {
      let resp = await this.$http.get(
        `/statistik/presbea/getdata/${this.selJenjang}`
      );

      //period
      for (let item of resp.data[0]) {
        item.label = `${item.nama} - Semester ${item.semester}`;
      }
      resp.data[0].unshift({ label: "SEMUA PERIODE", value: "semua" });
      this.period = resp.data[0];
      this.selPeriod = this.period[0];

      //bidang
      let bidang = [];
      for (let item of resp.data[5]) {
        bidang.push(item.bidang);
      }
      bidang.unshift("SEMUA BIDANG");
      this.bidang = bidang;
      this.selBidang = this.bidang[0];

      //tingkat
      let tingkat = [];
      for (let item of resp.data[6]) {
        tingkat.push(item.tingkat);
      }
      tingkat.unshift("SEMUA TINGKAT");
      this.tingkat = tingkat;
      this.selTingkat = this.tingkat[0];

      //peraih prestasi
      this.siswa = resp.data[1];
      this.getSiswa();

      //beasiswa
      resp.data[2].unshift({ label: "SEMUA BEASISWA", value: "semua" });
      this.bea = resp.data[2];
      this.selBea = this.bea[0];

      //tahun beasiswa
      let tahun = [];
      for (let item of resp.data[3]) {
        tahun.push(item.tahun);
      }
      tahun.unshift("SEMUA TAHUN");
      this.tahun = tahun;
      this.selTahun = this.tahun[0];

      //jenis
      this.selJenis = this.jenis[0];

      //sumber
      this.selSumber = this.sumber[0];

      //penerima beasiswa
      this.penerima = resp.data[4];
      this.getPenerima();
    },
    getSiswa() {
      let siswa = JSON.parse(JSON.stringify(this.siswa));

      if (this.selPeriod.value != "semua") {
        siswa = siswa.filter((x) => {
          return x.id_tahun_ajaran == this.selPeriod.value;
        });
      }

      if (this.selBidang != "SEMUA BIDANG") {
        siswa = siswa.filter((x) => {
          return x.bidang == this.selBidang;
        });
      }

      if (this.selTingkat != "SEMUA TINGKAT") {
        siswa = siswa.filter((x) => {
          return x.tingkat == this.selTingkat;
        });
      }

      let show = [];
      for (let i = 0; i < 10; i++) {
        if (siswa[(this.pagination.current - 1) * 10 + i]) {
          show.push(siswa[(this.pagination.current - 1) * 10 + i]);
        }
      }
      this.pagination.max = Math.ceil(siswa.length / 10);

      this.showSiswa = show;
      this.filter_pres = false;
    },
    getPenerima() {
      let siswa = JSON.parse(JSON.stringify(this.penerima));

      if (this.selBea.value != "semua") {
        siswa = siswa.filter((x) => {
          return x.id_beasiswa == this.selBea.value;
        });
      }

      if (this.selTahun != "SEMUA TAHUN") {
        siswa = siswa.filter((x) => {
          return x.tahun == this.selTahun;
        });
      }

      if (this.selJenis != "SEMUA JENIS") {
        siswa = siswa.filter((x) => {
          return x.jenis == this.selJenis;
        });
      }

      if (this.selSumber != "SEMUA SUMBER") {
        siswa = siswa.filter((x) => {
          return x.sumber == this.selSumber;
        });
      }

      let show = [];
      for (let i = 0; i < 10; i++) {
        if (siswa[(this.pagination_bea.current - 1) * 10 + i]) {
          show.push(siswa[(this.pagination_bea.current - 1) * 10 + i]);
        }
      }
      this.pagination_bea.max = Math.ceil(siswa.length / 10);

      this.showPenerima = show;
      this.filter_bea = false;
    },
  },
};
</script>
