<template>
  <q-dialog ref="dialog" @hide="onDialogHide">
    <q-card class="q-dialog-plugin">
      <p class="text-h6 text-grey-8 q-mt-sm text-center">Beasiswa</p>
      <q-card-section class="column q-col-gutter-sm">
        <q-input
          outlined
          readonly
          label="Nama Siswa"
          :value="bea.nama_siswa"
        ></q-input>
        <q-input
          outlined
          readonly
          label="Angkatan"
          :value="bea.tahun_masuk"
        ></q-input>
      </q-card-section>
      <q-separator></q-separator>
      <q-card-section class="column q-col-gutter-sm">
        <q-input
          outlined
          readonly
          autogrow
          label="Beasiswa"
          :value="bea.nama_beasiswa"
        ></q-input>
        <q-input outlined readonly label="Sumber" :value="bea.sumber"></q-input>
        <q-input outlined readonly label="Jenis" :value="bea.jenis"></q-input>
      </q-card-section>
      <q-separator></q-separator>
      <q-card-section>
        <q-input outlined readonly label="Tahun" :value="bea.tahun"></q-input>
        <q-input
          outlined
          readonly
          label="Nominal"
          :value="bea.nominal"
        ></q-input>
      </q-card-section>
      <q-card-actions align="right">
        <q-btn
          flat
          color="primary"
          label="Close"
          @click="onCancelClick"
        ></q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  props: ["bea"],
  data() {
    return {};
  },
  methods: {
    show() {
      this.$refs.dialog.show();
    },

    hide() {
      this.$refs.dialog.hide();
    },

    onDialogHide() {
      this.$emit("hide");
    },

    onOKClick() {
      this.$emit("ok");
      this.hide();
    },

    onCancelClick() {
      this.hide();
    }
  }
};
</script>
