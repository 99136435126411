import { render, staticRenderFns } from "./StatistikPrestasi.vue?vue&type=template&id=1e02fb89&"
import script from "./StatistikPrestasi.vue?vue&type=script&lang=js&"
export * from "./StatistikPrestasi.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QCard from 'quasar/src/components/card/QCard.js';
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import QTabPanels from 'quasar/src/components/tab-panels/QTabPanels.js';
import QTabPanel from 'quasar/src/components/tab-panels/QTabPanel.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QMarkupTable from 'quasar/src/components/markup-table/QMarkupTable.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QPagination from 'quasar/src/components/pagination/QPagination.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QCard,QTabs,QTab,QTabPanels,QTabPanel,QCardSection,QMarkupTable,QSeparator,QSelect,QBtn,QCardActions,QPagination,QDialog});qInstall(component, 'directives', {ClosePopup});
